//api.js
import request from './request.js';

export function getcode(data) {
    return request({
        url: '/artwork/send?phone=' + data.phone,
        method: 'post',
        // data
    })
}

export function login(data) {
    return request({
        url: '/artwork/verify?phone=' + data.phone + '&code=' + data.code,
        method: 'post',
        // data
    })
}

export function artworkadd(data) {
    return request({
        url: '/artwork/add',
        method: 'post',
        data
    })
}

export function getlistdata(data) {
    return request({
        url: '/artwork/selectList?id=' + data.phone,
        method: 'get',
    })
}

export function selectPageList(data) {
    return request({
        url: '/artwork/selectPageList',
        method: 'post',
        data
    })
}

export function exportData(data) {
    return request({
        url: '/artwork/exportData',
        method: 'post',
        data,
        responseType: "blob"
    })
}

export function findOne(data) {
    return request({
        url: '/user/findOne?phone=' + data.phone,
        method: 'get',
    })
}

export function useradd(data) {
    return request({
        url: '/user/add',
        method: 'post',
        data
    })
}

export function artworkselectOne(data) {
    return request({
        url: '/artwork/selectOne?id=' + data.id,
        method: 'get',
    })
}
export function userupdate(data) {
    return request({
        url: '/user/update',
        method: 'post',
        data
    })
}

export function artworkselectList(data) {
    return request({
        url: '/artwork/selectList?phone=' + data,
        method: 'get',
    })
}

export function artworkbrowse(data) {
    return request({
        url: '/artwork/browse?artworkId=' + data,
        method: 'get',
    })
}

export function artworkvote(data) {
    return request({
        url: '/artwork/vote',
        method: 'post',
        data
    })
}
export function artworksoldOut(data) {
    return request({
        url: '/artwork/soldOut?artworkId=' + data,
        method: 'get',
    })
}

export function getosstoken() {
    return request({
        url: '/file/getStsConfig',
        method: 'get',
    })
}