import axios from 'axios'
import { MessageBox, Message } from 'element-ui'

const service = axios.create({
    baseURL: '/api', // url = base url + request url
    timeout: 0 // request timeout
})

service.interceptors.request.use(
    config => {
        return config
    },
    error => {
        console.log(error) // for debug
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        return response
    },
    error => {
        console.log(error, 'errorerrorerror');
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
